import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import React, { useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import UiKitProvider from "../libs/uikit/UiKitProvider";
import createCache from "@emotion/cache";
import { ClientStyleContext } from "~/root.tsx";
import { CacheProvider } from "@emotion/react";
import posthog from "posthog-js";

type Env = {
  SENTRY_DSN: string;
};

declare global {
  let ENV: Env;
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    ENV: Env;
  }
}

interface ClientCacheProviderProps {
  children: React.ReactNode;
}
function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = React.useState(createCache({ key: "css" }));

  const clientStyleContextValue = React.useMemo(
    () => ({
      reset() {
        setCache(createCache({ key: "css" }));
      },
    }),
    []
  );

  return (
    <ClientStyleContext.Provider value={clientStyleContextValue}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

try {
  process.env.NODE_ENV !== "development" &&
    Sentry.init({
      dsn: ENV.SENTRY_DSN,
      tracesSampleRate: 1,

      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
        Sentry.consoleIntegration(),
      ],
    });
} catch (err) {}

// @from: https://github.com/facebook/react/issues/24430#issuecomment-1156537554
document.querySelectorAll("html > script, html > input").forEach((s) => {
  s.parentNode?.removeChild(s);
});

function PosthogInit() {
  useEffect(() => {
    const postHogId = process.env.POSTHOG_ID;
    if (!postHogId) return;

    posthog.init(postHogId, {
      api_host: "https://eu.i.posthog.com",
      person_profiles: "always",
    });
  }, []);

  return null;
}

const hydrate = () => {
  React.startTransition(() => {
    ReactDOM.hydrateRoot(
      document,
      <ClientCacheProvider>
        <UiKitProvider>
          <RemixBrowser />
          <PosthogInit />
        </UiKitProvider>
      </ClientCacheProvider>
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  setTimeout(hydrate, 1);
}
